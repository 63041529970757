<template>

<v-row no-gutters justify="center" style="margin-top:5vh">
  <v-fade-transition>
  <v-card v-show="imageReady" flat class="pa-3" width="370" max-width="370">
    <v-row no-gutters justify="center">
      <img
          id="loginImage"
            class="mx-5 px-5 pt-5 mt-5"
            style="width: 80%; max-height: 180px; object-fit: contain;"
            src="https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FNovoRPM%20Horiz.svg?alt=media&token=83350ffc-36cb-4603-9b59-d4a0dd968043"
          />
    </v-row>

    <v-row justify="center" class="font-weight-light mt-7 mb-0" style="font-size: 24px" no-gutters>
      <span>Password Reset</span>
    </v-row>
    <v-expand-transition>
    <div v-show="!emailSent">
    <v-card-text class="px-1 pb-0">
      Enter the email address that you use to log in. We'll send you an email with a link to reset your password.
    </v-card-text>
    
    </div>
    </v-expand-transition>
    <v-form ref="resetPasswordForm">
            <v-text-field class="mt-6"
              outlined
              label="Email"
              name="email"
              type="text"
              required
              v-model="email"
              :rules="emailRules"
              v-on:keyup.enter="sendReset()"
            />
          </v-form>
          <v-expand-transition>
    <div v-show="emailSent">
      <v-card-text class="mt-0 pt-0 px-0">
The password reset email has been sent. Please check your email and follow the instructions to reset your password. Once you've reset your password. Return to the login screen to enter your new password.
      </v-card-text>
    </div>
          </v-expand-transition>
          <v-row justify="space-between" no-gutters>
          <v-btn
            color="primary"
            text
            @click="switchToLogin"
            >Back to Login</v-btn
          >
          <v-spacer />
          <v-btn color="primary" depressed @click="sendReset">
            <v-fade-transition hide-on-leave>
            <span v-show="!resetLoading">
            <span v-if="emailSent">Resend</span>
            <span v-else>Send</span>
            </span>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
            <v-progress-circular v-show="resetLoading" size="20" indeterminate/>
            </v-fade-transition>
            </v-btn>
        </v-row>
  </v-card>
  </v-fade-transition>
</v-row>

  <!-- <v-layout class="patient-page" style="padding-top:5%" justify-center>
    <v-card
      outlined
      style="max-width: 500px; width: 100%;"
      :loading="resetLoading"
      flat
    >
      <div style="overflow:hidden">
        <v-layout row justify-center class="pt-2">
          <img
          v-if="appTheme"
            class="mx-5 px-5 pt-5 mt-5"
            style="width: 90%; max-height: 300px; object-fit: contain;"
            :src="appTheme.login"
          />
        </v-layout>
        <v-card-text>
          <v-layout style="font-weight:500; font-size: 15px;" class="mx-1 mb-1 mt-2">
            Password Reset
          </v-layout>
          <v-layout class="mx-1 mb-5">
          Enter the email address that you use to log in. We'll send you an email with a link to reset your password.
          </v-layout>
          <v-form ref="resetPasswordForm">
            <v-text-field
              outlined
              label="Email"
              name="email"
              type="text"
              required
              v-model="email"
              :rules="emailRules"
              v-on:keyup.enter="sendReset()"
            />
          </v-form>
          <v-layout v-if="emailSent" class="mx-1 mb-3">
The password reset email has been sent. Please check your email and follow the instructions to reset your password. Once you've reset your password. Return to the login screen to enter your new password.           </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="switchToLogin"
            >Back to Login</v-btn
          >
          <v-spacer />
          <v-btn color="primary" depressed @click="sendReset"><span v-if="emailSent">Resend</span><span v-else>Send</span></v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-layout> -->
</template>

<script>
// @ is an alias to /src
// import LoginComponent from "@/components/LoginComponent.vue";
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  name: 'resetpwpage',
  components: {},
  data() {
    return {
      imageReady: false,
      resetLoading: false,
      email: null,
      emailRules: [v => !!v || 'E-mail is required'],
      emailSent: false
    }
  },
  computed: {
    ...mapState('auth', ['user', 'appTitle', 'subdomain', 'loginURL', 'appTheme']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    tagline() {
      return 'Powered by OneChart Health'
    }
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    ...mapActions('provider', ['getProviderAuth']),
    sendReset() {
      if (this.$refs.resetPasswordForm.validate()) {
        this.resetLoading = true
        this.resetPassword(this.email).then(() => {
          bus.$emit('toast', { type: 'success', text: 'Email Sent' })
          this.emailSent = true
          this.resetLoading = false
        })
        .catch(error => {
        bus.$emit('toast', { type: 'error', text: 'Error Sending Email' })
        this.resetLoading = false
        return error
      })
      }
    },
    switchToLogin() {
      this.$router.push({ name: 'Login', query: this.$route.query })
    },
  },
  mounted() {
    let img = document.getElementById('loginImage')
    let vm = this
    img.onload = function() {
      vm.imageReady = true
    };

    img.onerror = function() {
      vm.imageReady = true
    };
  }
}
</script>
